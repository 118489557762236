<template>
  <div
    class="cp-availability"
    :class="{ paused: chargingPoint.availability.paused }"
  >
    <b-row
      v-for="(value, key) in chargingPointAvailability.days"
      :key="key"
      class="day"
    >
      <b-col class="d-flex flex-wrap align-items-center">
        <label>{{ getDayLabel(key) }}</label>
        <div class="cp-schedule">
          <div
            v-for="h in Array.apply(null, Array(24)).map((_, i) => i)"
            :key="key + h"
            :class="{
              available: isAvailable(value, h),
              'half-right': isAvailableRight(value, h),
              'half-left': isAvailableLeft(value, h)
            }"
          ></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ChargingPointAvailabilityMixin } from '@/mixins/cp-availability'

const moment = require('moment')
moment.locale('fr-FR')

export default {
  name: 'charging-point-availability',
  props: {
    chargingPoint: { type: Object, required: true }
  },
  mixins: [ChargingPointAvailabilityMixin],
  methods: {
    isAvailable({ available, startTime, endTime }, h) {
      if (available && startTime && endTime) {
        const [sHours, sMinutes] = startTime.split(':')
        const [eHours, eMinutes] = endTime.split(':')

        return (
          moment({ hour: sHours, minute: sMinutes }).isSameOrBefore(
            moment({ hour: h })
          ) &&
          moment({ hour: eHours, minute: eMinutes }).isSameOrAfter(
            moment({ hour: h, minute: 59 })
          )
        )
      }

      return false
    },
    isAvailableLeft(value, h) {
      const { available, endTime } = value

      if (available && endTime) {
        const [eHours, eMinutes] = endTime.split(':')

        return moment({ hour: eHours, minute: eMinutes }).isBetween(
          moment({ hour: h }),
          moment({ hour: h + 1 })
        )
      }

      return false
    },
    isAvailableRight(value, h) {
      const { available, startTime } = value

      if (available && startTime) {
        const [sHours, sMinutes] = startTime.split(':')

        return moment({ hour: sHours, minute: sMinutes }).isBetween(
          moment({ hour: h }),
          moment({ hour: h + 1 })
        )
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

.cp-availability {
  position: relative;

  &.paused {
    opacity: 0.5;

    &::after {
      position: absolute;
      top: 50px;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      content: 'EN PAUSE';
      font-size: 48px;
      font-weight: bolder;
      text-align: center;
      transform: rotate(-45deg);
    }
  }

  .row.day {
    margin-bottom: 15px;
    height: 17px;

    label {
      margin: 0 5px 0 0;
      width: 66px;
      font-weight: $font-weight-bold;
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.65px;
      color: $dark;
      text-transform: uppercase;
    }

    .cp-schedule {
      display: flex;
      justify-content: space-between;
      width: 263px;
      min-width: 263px;

      div {
        width: 10px;
        height: 10px;
        background-color: #d0d0d0;

        &.available {
          background-color: $provider-yellow;
        }

        &.half-left {
          background: linear-gradient(90deg, $provider-yellow 50%, #d0d0d0 50%);
        }

        &.half-right {
          background: linear-gradient(90deg, #d0d0d0 50%, $provider-yellow 50%);
        }
      }
    }
  }
}
</style>
