var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cp-availability",
      class: { paused: _vm.chargingPoint.availability.paused }
    },
    _vm._l(_vm.chargingPointAvailability.days, function(value, key) {
      return _c(
        "b-row",
        { key: key, staticClass: "day" },
        [
          _c("b-col", { staticClass: "d-flex flex-wrap align-items-center" }, [
            _c("label", [_vm._v(_vm._s(_vm.getDayLabel(key)))]),
            _c(
              "div",
              { staticClass: "cp-schedule" },
              _vm._l(
                Array.apply(null, Array(24)).map(function(_, i) {
                  return i
                }),
                function(h) {
                  return _c("div", {
                    key: key + h,
                    class: {
                      available: _vm.isAvailable(value, h),
                      "half-right": _vm.isAvailableRight(value, h),
                      "half-left": _vm.isAvailableLeft(value, h)
                    }
                  })
                }
              ),
              0
            )
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }