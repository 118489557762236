<template>
  <div id="my-charging-point">
    <HeaderNavigation title="mes points de recharge" />
    <b-container class="main-container">
      <h1>
        Point de recharge #{{ chargingPoint.id }}
        <button class="close" @click="$router.back()">
          <span>&times;</span>
        </button>
      </h1>
      <section>
        <h2>Informations du point de recharge</h2>
        <ChargingPointResume :chargingPoint="chargingPoint" />
        <footer \class="text-right">
          <b-button
            :to="{
              name: 'my-charging-point-details-edit',
              params: { id: id }
            }"
            variant="dark"
            class="modify"
            >MODIFIER LES INFORMATIONS</b-button
          >
        </footer>
      </section>
      <section>
        <h2>Horaire de disponibilité</h2>
        <ChargingPointAvailability :chargingPoint="chargingPoint" />
        <footer class="text-right">
          <b-button
            :to="{
              name: 'my-charging-point-availability-edit',
              params: { id: id }
            }"
            variant="dark"
            class="modify"
            >MODIFIER LES HORAIRES</b-button
          >
        </footer>
      </section>
      <section>
        <h2>Photos du point de recharge</h2>
        <b-row class="d-flex flex-wrap justify-content-start">
          <b-col
            v-for="(photo, idx) in chargingPoint.photos"
            :key="idx"
            cols="4"
          >
            <b-img
              :src="photo.url"
              blank-color="#d0d0d0"
              class="cp-photo"
              @click="removeImage(photo.id)"
              :blank="!photo.url"
            />
          </b-col>
        </b-row>
        <footer class="text-right image-picker d-flex justify-content-end">
          <file-upload
            v-show="false"
            ref="fuchargingPoint"
            input-id="fuchargingPoint"
            :multiple="true"
            post-action="/post.method"
            put-action="/put.method"
            class="btn btn-ligth modify d-flex justify-content-center"
            v-model="chargingPointFiles"
            @input-file="inputFile"
            @input-filter="inputFilterchargingPoint"
          >
            <span class="caption align-self-center">+</span>
          </file-upload>
        </footer>
      </section>
      <b-row>
        <b-col class="text-center">
          <b-button
            v-loader.disable="deleting"
            class="delete"
            variant="warning"
            @click="onDeleteChargingPoint"
          >
            Supprimer ce point de recharge</b-button
          >
        </b-col>
      </b-row>
      <section v-if="chargingPoint.private">
        <h2>Abonnés</h2>
        <b-row>
          <b-col class="d-flex flex-wrap justify-content-between">
            <b-card
              v-for="(subscriber, idx) in chargingPoint.subscribers"
              :key="idx"
              class="subscriber"
              no-body
            >
              <a
                class="no-gutters row h-100"
                @click="onRemoveGuest(subscriber)"
                href="#"
              >
                <div
                  class="col-1 d-flex align-items-center justify-content-center"
                >
                  <!-- PLACE PHOTO HERE -->
                </div>
                <b-card-body>
                  <b-card-title
                    title-tag="span"
                    class="title-headline text-uppercase"
                    >{{ subscriber.name }}</b-card-title
                  >
                  <br />
                  <b-card-sub-title
                    sub-title-tag="span"
                    sub-title-text-variant=""
                    >{{ subscriber.email }}<br />{{ subscriber.pseudo }}
                  </b-card-sub-title>
                </b-card-body>
              </a>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button
              variant="light"
              class="evenir-squared-button"
              @click="onInviteGuest"
              >+</b-button
            >
          </b-col>
        </b-row>
        <b-modal
          id="inviteGuestModal"
          ref="inviteGuestModal"
          size="sm"
          ok-variant="dark"
          @ok="inviteGuest"
          ok-only
          centered
        >
          <b-form-group label="E-MAIL DU COMPTE">
            <b-form-input type="email" v-model="guestEmail" required />
          </b-form-group>

          <template #modal-footer="{ ok }">
            <b-button v-loader.disable="inviting" variant="dark" @click="ok()">
              Envoyer
            </b-button>
          </template>
        </b-modal>
        <b-modal
          id="removeGuestModal"
          ref="removeGuestModal"
          size="sm"
          ok-variant="dark"
          :disabled="removing"
          @ok="removeGuest"
          ok-only
          centered
        >
          Voulez-vous désabonner l’utilisateur <b>{{ guestEmail }}</b> ?

          <template #modal-footer="{ ok }">
            <b-button v-loader.disable="removing" variant="dark" @click="ok()">
              Désabonner
            </b-button>
          </template>
        </b-modal>
      </section>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import ChargingPointResume from '@/components/ChargingPoints/ChargingPointResume.vue'
import ChargingPointAvailability from '@/components/ChargingPoints/ChargingPointAvailability.vue'
import { ChargingPointAvailabilityMixin } from '@/mixins/cp-availability'
import FileUpload from 'vue-upload-component'
import { Routes } from '@/router.js'

const moment = require('moment')
moment.locale('fr-FR')

export default {
  name: 'my-charging-point',
  components: {
    HeaderNavigation,
    ChargingPointResume,
    ChargingPointAvailability,
    FileUpload
  },
  props: {
    id: { type: String, required: true }
  },
  mixins: [ChargingPointAvailabilityMixin],
  data() {
    return {
      chargingPointFiles: [],
      guestEmail: undefined,
      deleting: false,
      inviting: false,
      removing: false
    }
  },
  computed: {
    chargingPoint() {
      return this.$store.getters.getChargingPoint(this.id) || {}
    }
  },
  methods: {
    ...mapActions([
      'loadChargingPoint',
      'deleteChargingPoint',
      'addChargingPointPhoto',
      'deleteChargingPointPhoto'
    ]),
    onDeleteChargingPoint() {
      this.deleting = true

      this.deleteChargingPoint({
        api: this.$apiService,
        id: this.chargingPoint.id
      })
        .then(() => this.$router.push(Routes.ACCOUNT))
        .catch(err =>
          console.error('Uncaught error while deleteing charging point: ', err)
        )
        .finally(() => (this.deleting = false))
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
        }
      }
    },
    inputFilterchargingPoint(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          // TODO Toast notification

          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }

      this.addChargingPointPhoto({
        api: this.$apiService,
        cpId: this.id,
        photo: newFile
      }).catch(err => {
        console.error('[MyCP] Uncaught error while adding CP photo.', err)
      })
    },
    removeImage(photoId) {
      console.log('Delting picture...')
      this.deleteChargingPointPhoto({
        api: this.$apiService,
        cpId: this.id,
        photoId
      }).catch(err => {
        console.error('[MyCP] Uncaught error while adding CP photo.', err)
      })
    },
    onInviteGuest() {
      this.guestEmail = ''
      this.$refs.inviteGuestModal.show()
    },
    onRemoveGuest(guest) {
      this.guestEmail = guest.email
      this.$refs.removeGuestModal.show()
    },
    inviteGuest(evt) {
      evt.preventDefault()

      // TODO: Validate email
      if (!this.guestEmail) {
        return
      }

      this.inviting = true

      this.$store
        .dispatch('inviteGuest', {
          api: this.$apiService,
          cpId: this.id,
          guest: { email: this.guestEmail }
        })
        .then(() => this.$nextTick(() => this.$refs.inviteGuestModal.hide()))
        .finally(() => (this.inviting = false))
    },
    removeGuest(evt) {
      evt.preventDefault()
      this.removing = true

      this.$store
        .dispatch('removeGuest', {
          api: this.$apiService,
          cpId: this.id,
          email: this.guestEmail
        })
        .then(() => this.$nextTick(() => this.$refs.removeGuestModal.hide()))
        .finally(() => (this.removing = false))
    }
  },
  mounted() {
    this.loadChargingPoint({ api: this.$apiService, id: this.id }).then(cp => {
      if (!cp) {
        if (this.$store.getters.hasChargingPoints) {
          this.$router.push(Routes.MY_CHARGING_POINTS)
        } else {
          this.$router.push(Routes.ACCOUNT)
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#my-charging-point {
  min-height: 100vh;
  height: 100%;
  background-color: #f8f8f8;

  h1,
  h2 {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  h1 {
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0;
    color: $dark;

    button.close {
      font-weight: $font-weight-regular;
      font-size: 2em;
      line-height: unset;
      color: #152536;
      opacity: 1;
    }
  }

  section {
    margin-top: 20px;
    margin-bottom: 20px;

    h2 {
      font-size: 13px;
      line-height: 12px;
      letter-spacing: 0.65px;
      color: #cecece;
    }

    .btn.modify {
      width: 215px;
    }

    .cp-photo {
      margin-bottom: 20px;
      width: 140px;
      height: 80px;
      object-fit: cover;
    }

    .image-picker {
      .btn {
        line-height: 24px;

        &.modify {
          width: 50px;
          height: 50px;

          .caption {
            content: '+';
          }
        }
      }
    }

    .subscriber {
      background-color: #fff;
      border-radius: 10px;
      border: none;
      width: 100%;
      margin-bottom: 10px;
      a {
        padding-left: 30px;
        .card-body {
          text-decoration: none;
          .card-subtitle {
            color: #cecece;
            font-size: 14px;
            opacity: 1;
            letter-spacing: 1px;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
      svg {
        fill: $dark;
        stroke: $dark;
      }
      &.invalid-data {
        background-color: $invalid-data-color;
        a {
          .card-body {
            .card-subtitle {
              color: #fff;
              opacity: 0.5;
            }
          }
        }
        &:hover {
          svg {
            fill: #fff;
            stroke: #fff;
          }
          .title-headline {
            color: #fff;
          }
        }
      }
      &:active {
        background-color: #ff995a;
      }
    }
  }

  .btn.delete {
    width: 216px;
    letter-spacing: 0.65px;
    line-height: 12px;
  }
}
</style>
